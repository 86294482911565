import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import ScholarshipTable from "./components/ScholarshipTable";
import Header from "./components/Header";
import PrivacyPolicy from "./pages/PrivacyPolicy"; 
import "./assets/styles/App.css";
import SignInButton from "./components/buttons/SignInButton";
import logo from './assets/images/scholarship_clipper_logo.png';

const App = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      console.log("Access Token:", auth.user?.access_token);
    }
  }, [auth.isAuthenticated, auth.user?.access_token]);
  

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("code") || urlParams.has("state")) {
      setTimeout(() => {
        window.history.replaceState({}, document.title, window.location.pathname);
      }, 500);
    }
  }, []);

  if (window.location.pathname === "/privacy-policy") {
    return <PrivacyPolicy />;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Encountering error... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated) {
    return (
      <div className="app-container logo-container">
        <img src={logo} alt="Logo" style={{ width: '350px' }} />
        <SignInButton onSignIn={() => auth.signinRedirect()} />
      </div>
    );
  }

  const handleSignOut = () => {
    auth.removeUser();
  };

  // Once authenticated, show the scholarships page
  return (
    <div className="app-container">
      <Header logo={logo} email={auth.user?.profile.email} onSignOut={handleSignOut} />
      <ScholarshipTable />
    </div>
  );
};

export default App;
