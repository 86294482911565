import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
      <h1>Privacy Policy</h1>
      <p>Last updated: Dec 24, 2024</p>

      <h2>1. Introduction</h2>
      <p>
        This Privacy Policy explains how Scholarship Clipper ("we," "our," or "the extension")
        collects, uses, and protects your information when using the extension. This extension
        is designed for personal use and restricted to family members.
      </p>

      <h2>2. Information We Collect</h2>
      <p>The extension collects the following information to function:</p>
      <ul>
        <li>Authentication tokens from AWS Cognito for secure login.</li>
        <li>
          Form inputs such as scholarship names, URLs, amounts, deadlines, and other
          scholarship-related details.
        </li>
      </ul>
      <p>
        This data is temporarily stored in your browser's local storage for functionality
        and is not shared or transmitted to any third-party services except for our secure
        API (<code>https://api.scholarshipclipper.com</code>) and AWS Cognito for authentication.
      </p>

      <h2>3. How We Use Your Information</h2>
      <p>We use the collected information to:</p>
      <ul>
        <li>Authenticate users securely via AWS Cognito.</li>
        <li>Submit scholarship data to the Scholarship Clipper API for record-keeping and management.</li>
      </ul>

      <h2>4. Data Sharing and Disclosure</h2>
      <p>
        We do not sell, share, or disclose your data to third parties, except:
      </p>
      <ul>
        <li>With AWS Cognito for secure login authentication.</li>
        <li>With the Scholarship Clipper API, as necessary for extension functionality.</li>
      </ul>

      <h2>5. Security</h2>
      <p>
        We take reasonable measures to protect your data, including the use of HTTPS for
        all external communication.
      </p>

      <h2>6. Your Choices</h2>
      <p>
        You can remove the extension at any time, which will also delete all locally stored data.
      </p>

      <h2>7. Contact Us</h2>
      <p>
      If you have any questions or concerns about this Privacy Policy, please contact us at:  
        <a href="mailto:supportl@scholarshipclipper.com">support@scholarshipclipper.com</a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;

