import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getScholarships, deleteScholarship, updateScholarship, createScholarship } from '../services/scholarships';
import { useAuth } from "react-oidc-context";
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DeleteIcon from '@mui/icons-material/Delete';
import LinearProgress from '@mui/material/LinearProgress';

import './scholarshipTable.css';

const booleanCellRenderer = (value) => (
  <span style={{ color: value ? 'green' : 'red', fontWeight: 'bold' }}>
    {value ? '✔' : '✘'}
  </span>
);

const ScholarshipTable = () => {
  const [userId, setUserId] = useState(1); // Default to David's userId
  const auth = useAuth(); // Hook to get authentication data
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const goalAmount = 30000;

  const totalWon = useMemo(
    () => rows.reduce((sum, row) => (row.won ? sum + (parseFloat(row.amount) || 0) : sum), 0),
    [rows]
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (auth.user) {
          const token = auth.user.access_token;
          const data = await getScholarships(userId, token);
          setRows(data);
        }
      } catch (error) {
        console.error("Error fetching scholarships:", error);
        alert("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (userId !== "add") {
      fetchData();
    }
  }, [auth.user, userId]);

  const handleDelete = useCallback(
    async (id) => {
      const confirmation = window.confirm(
        "Are you sure you want to delete this scholarship? This action cannot be undone."
      );
      if (!confirmation) return;

      try {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));

        if (auth.user) {
          const token = auth.user.access_token;
          await deleteScholarship(id, token);
        }
      } catch (error) {
        console.error("Error deleting row:", error);
        alert("Failed to delete row.");
      }
    },
    [auth.user]
  );

  const handleProcessRowUpdate = useCallback(
    async (updatedRow) => {
      const originalRow = rows.find((row) => row.id === updatedRow.id);
      if (!originalRow) {
        console.error(`Original row not found for ID: ${updatedRow.id}`);
        alert("Failed to find original row.");
        return updatedRow;
      }

      const changedFields = Object.fromEntries(
        Object.entries(updatedRow).filter(([key, value]) => key !== "count" && value !== originalRow[key])
      );

      if (!Object.keys(changedFields).length) return updatedRow;

      try {
        if (auth.user) {
          const token = auth.user.access_token;
          await updateScholarship(updatedRow.id, changedFields, token);
        }
        setRows((prevRows) =>
          prevRows.map((row) => (row.id === updatedRow.id ? { ...row, ...changedFields } : row))
        );
        return { ...updatedRow, ...changedFields };
      } catch (error) {
        console.error("Error updating row:", error);
        alert("Failed to save changes.");
        return originalRow;
      }
    },
    [auth.user, rows]
  );

  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const formData = new FormData(e.target); // Collect form data
    const scholarshipData = {
      userId: formData.get("user"),
      name: formData.get("name"),
      url: formData.get("url"),
      amount: parseFloat(formData.get("amount")) || 0,
      deadline: formData.get("deadline"),
      renewable: formData.get("renewable") === "on",
      notes: formData.get("notes"),
    };

    try {
      const token = auth.user?.access_token;

      if (!token) {
        alert("You are not authorized to perform this action.");
        return;
      }

      await createScholarship(scholarshipData, token);

      alert("Scholarship added successfully!");
      e.target.reset(); // Reset the form after successful submission
    } catch (error) {
      console.error("Error adding scholarship:", error);
      alert("Failed to add scholarship. Please try again.");
    }
  };

  const columns = useMemo(() => [
    { field: 'count', headerName: '#', width: 30, editable: false },
    {
      field: 'name',
      headerName: 'Scholarship',
      width: 250,
      editable: true,
      renderCell: (params) => (
        <Tooltip title={params.row.name || ''} arrow>
          <a href={params.row.url} target="_blank" rel="noopener noreferrer">
            {params.value}
          </a>
        </Tooltip>
      ),
    },
    { field: 'amount', headerName: 'Amount', width: 80, editable: true, renderCell: (params) => `$${params.value}` },
    { field: 'deadline', headerName: 'Deadline', width: 120, editable: true },
    {
      field: 'submitted',
      headerName: 'Submitted',
      width: 100,
      editable: true,
      type: 'boolean',
      renderCell: ({ value }) => booleanCellRenderer(value),
    },
    {
      field: 'won',
      headerName: 'Won',
      width: 80,
      editable: true,
      type: 'boolean',
      renderCell: ({ value }) => booleanCellRenderer(value),
    },
    {
      field: 'renewable',
      headerName: 'Renewable',
      width: 100,
      editable: true,
      type: 'boolean',
      renderCell: ({ value }) => booleanCellRenderer(value),
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 370,
      editable: true,
      renderCell: (params) => (
        <Tooltip title={params.row.notes || ''} arrow>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'delete',
      headerName: 'Trash',
      width: 70,
      renderCell: (params) => (
        <DeleteIcon
          onClick={() => handleDelete(params.row.id)}
          style={{ color: '#888', cursor: 'pointer' }}
        />
      ),
    },
  ], [handleDelete]);

  return (
    <div className="sortable-table-container">
      <div className="tabs-header">
        <Tabs
          value={userId}
          onChange={(e, newValue) => setUserId(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {['David', 'Jonathan', 'Jacob'].map((name, index) => (
            <Tab key={name} label={name} value={index + 1} />
          ))}
          <Tab icon={<span className="add-tab">+</span>} value="add" />
        </Tabs>
        {userId !== "add" && (
          <div className="progress-container">
            <p className="progress-text">
              Total Won: <strong>${totalWon.toLocaleString()}</strong> / ${goalAmount.toLocaleString()}
            </p>
            <LinearProgress
              variant="determinate"
              value={(totalWon / goalAmount) * 100}
              className="progress-bar"
            />
          </div>
        )}
      </div>
      <div className="content-area">
        {userId === "add" ? (
          <div className="add-scholarship-container">
            <h3>Add New Scholarship</h3>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label htmlFor="user">User:</label>
                <select id="user" name="user" required>
                  <option value="1">David</option>
                  <option value="2">Jonathan</option>
                  <option value="3">Jacob</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="name">Scholarship Name:</label>
                <input id="name" type="text" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="url">URL:</label>
                <input id="url" type="url" name="url" required />
              </div>
              <div className="form-group">
                <label htmlFor="amount">Amount:</label>
                <input id="amount" type="number" name="amount" required />
              </div>
              <div className="form-group">
                <label htmlFor="deadline">Deadline:</label>
                <input id="deadline" type="date" name="deadline" required />
              </div>
              <div className="form-group-checkbox">
                <input id="renewable" type="checkbox" name="renewable" />
                <label htmlFor="renewable">Renewable</label>
              </div>
              <div className="form-group">
                <label htmlFor="notes">Notes:</label>
                <textarea id="notes" name="notes" rows="4"></textarea>
              </div>
              <button type="submit" className="submit-button">Submit</button>
            </form>
          </div>
        ) : (
          <div className="sortable-table-wrapper">
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              pagination
              loading={loading}
              disableSelectionOnClick
              processRowUpdate={handleProcessRowUpdate}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ScholarshipTable;
